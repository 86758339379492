/* eslint-disable no-unused-expressions */
<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">球星管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0"></div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #columns-photo="{ data }">
                <div>
                  <div class="intro-y pr-1">
                    <div class="box">
                      <div
                        class="nav-tabs flex flex-col sm:flex-row justify-center lg:justify-start  border-b border-gray-200 dark:border-dark-5"
                      >
                        <a
                          data-toggle="tab"
                          data-target="#photo"
                          href="javascript:;"
                          class="py-2 sm:mr-8 active"
                          >球星代表圖</a
                        >
                        <a
                          data-toggle="tab"
                          data-target="#gallery"
                          href="javascript:;"
                          class="py-2 sm:mr-8"
                          >球星圖集</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="tab-content">
                    <div id="photo" class="tab-content__pane active">
                      <div class="grid grid-cols-12 gap-6 py-5">
                        <div class="col-span-6 justify-self-center">
                          <label>運動員列表縮圖</label>
                          <FileUploader
                            id="news-photo-thumbnail-uri"
                            style="min-width:150px;max-width:150px;min-height:150px;max-height:150px;"
                            mode="image"
                            :modelValue="data.Photo?.ThumbnailUri"
                            :action="uploadAction"
                            :autoUpload="true"
                            :limitedHeight="400"
                            :limitedWidth="400"
                            @update:modelValue="
                              value => {
                                if (data.Photo) data.Photo.ThumbnailUri = value;
                                else data.Photo = { ThumbnailUri: value };
                              }
                            "
                            @filter="uploaderFilter"
                          ></FileUploader>
                        </div>
                        <div class="col-span-6 justify-self-center">
                          <label>運動員介紹上方主圖</label>
                          <FileUploader
                            id="news-photo-uri"
                            style="min-width:150px;max-width:150px;min-height:123px;max-height:123px;"
                            mode="image"
                            class=" mb-12"
                            :modelValue="data.Photo?.Uri"
                            :action="uploadAction"
                            :autoUpload="true"
                            :limitedHeight="548"
                            :limitedWidth="508"
                            @update:modelValue="
                              value => {
                                if (data.Photo) data.Photo.Uri = value;
                                else data.Photo = { Uri: value };
                              }
                            "
                            @filter="uploaderFilter"
                          ></FileUploader>
                        </div>
                      </div>
                    </div>
                    <div id="gallery" class="tab-content__pane">
                      <div class="">
                        <slider-editor
                          ref="sliderEditor"
                          :itemWidth="548"
                          :itemHeight="508"
                          :navigation="true"
                          :width="sliderWidth"
                          :filePostAction="`${baseUrl}/api/files`"
                          :formOptions="albumFormOptions"
                          :modelValue="data.PictureAlbum?.Pictures"
                          @add="onSliderEdit"
                          @edit="onSliderEdit"
                          @save="onSliderSave"
                          @remove="onSliderRemove"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <template #columns-content="{ data }">
                <CKEditor
                  v-model="data.Career.Content"
                  @update:modelValue="value => (data.Career.Content = value)"
                  :editor="classicEditor"
                  :config="editorConfig"
                />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, {
  Condition,
  defineComponent,
  onMounted,
  Operator,
  reactive,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import { VueUploadItem } from "vue-upload-component";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import formatDate from "xe-utils/toDateString";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SliderEditor
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});
    const category = ref([]);
    const uploader = ref({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions = {
      stripe: false,
      title: "球星",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "類別清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "類別清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true,
          width: "300px",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
          // filters: [{ checked: false, label: "包含" }],
          // slots: { filter: "filter-name" }
        },
        {
          field: "CategoryId",
          title: "分類",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$select",
            options: category
          }
        },
        {
          field: "Country",
          title: "國家",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
          // editRender: {
          //   name: "$select",
          //   options: category
          // }
        },
        {
          field: "ReleaseDate",
          title: "發佈日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            formatDate(new Date(cellValue), "yyyy/MM/dd")
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => (cellValue ? "是" : "否")
        },
        {
          field: "StartDate",
          title: "上架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : ""
        },
        {
          field: "EndDate",
          title: "下架日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : ""
        },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        query: model
          ? params => {
              return model.dispatch("athleticStar/query", params);
            }
          : undefined,
        queryAll: model
          ? () => model.dispatch("athleticStar/query")
          : undefined,
        save: model
          ? params => model.dispatch("athleticStar/save", params)
          : undefined
      },
      modalConfig: { width: "90%", height: "90%", showFooter: true }
    };

    const formOptions = {
      titleWidth: 120,
      titleAlign: "right",
      items: [
        {
          field: "Photo.Uri",
          span: 24,
          slots: { default: "columns-photo" }
        },
        {
          field: "Name",
          title: "姓名",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入運動員姓名", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "Country",
          title: "國家",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入運動員國家", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "CategoryId",
          title: "運動員分類",
          span: 12,
          itemRender: {
            name: "$select",
            options: category,
            props: { placeholder: "請輸入運動員國家", clearable: true }
          }
        },
        {
          field: "Published",
          title: "發佈",
          span: 12,
          itemRender: {
            name: "$switch"
          }
        },
        // {
        //   field: "ReleaseDate",
        //   title: "發佈日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: {
        //       placeholder: "請輸入運動員國家",
        //       clearable: true,
        //       type: "date"
        //     }
        //   }
        // },
        // {},
        // {
        //   field: "StartDate",
        //   title: "上架日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: {
        //       placeholder: "請輸入運動員國家",
        //       clearable: true,
        //       type: "date"
        //     }
        //   }
        // },
        // {
        //   field: "EndDate",
        //   title: "下架日期",
        //   span: 12,
        //   itemRender: {
        //     name: "$input",
        //     props: {
        //       placeholder: "請輸入運動員國家",
        //       clearable: true,
        //       type: "date"
        //     }
        //   }
        // },
        {
          field: "Ordinal",
          title: "排序",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入運動員國家", clearable: true },
            attrs: { type: "number" }
          }
        },
        {
          field: "IG",
          title: "IG 連結",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入運動員 IG 連結", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "FB",
          title: "Facebook 連結",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入運動員 Facebook 連結",
              clearable: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "Twitter",
          title: "Twitter 連結",
          span: 12,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入運動員 Twitter 連結",
              clearable: true
            },
            attrs: { type: "text" }
          }
        },
        {
          field: "Career.Content",
          span: 24,
          slots: { default: "columns-content" }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    const albumFormOptions = {
      titleWidth: 100,
      titleAlign: "right",
      modalWidth: 400,
      items: [
        {
          field: "Name",
          title: "名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "LinkUrl",
          title: "圖片連結",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入圖片連結網址", clearable: true }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", max: 128 }],
        LinkUrl: [{ type: "string", max: 2000 }]
      }
    };

    onMounted(() => {
      var params = { condition: new Condition("Type", Operator.Equal, 2) };
      model?.dispatch("category/query", params).then(
        payload => {
          category.value = payload.map(el => {
            return { label: el.Name, value: el.Id };
          });
        },
        failure => {
          CloudFun.send("error", { subject: "操作失敗！", content: failure });
        }
      );
    });

    function schemaCustomization(editor) {
      editor.model.schema.extend("$block", {
        allowAttributes: "classList"
      });
      editor.conversion.attributeToAttribute({
        model: {
          key: "classList"
        },
        view: {
          key: "class"
        }
      });
    }

    const classicEditor = ClassicEditor;
    const editorConfig = {
      extraPlugins: [UploadAdapterPlugin, schemaCustomization],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "|",
          "undo",
          "redo"
        ]
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageTextAlternative",
          "imageStyle:full",
          "imageStyle:side",
          "linkImage"
        ]
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties"
        ]
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph"
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold text-xl" },
            title: "Heading 1",
            class: "ck-heading_heading1"
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold text-base" },
            title: "Heading 2",
            class: "ck-heading_heading2"
          }
        ]
      }
    };
    const sliderWidth = ref("100%");
    return {
      grid,
      gridOptions,
      formOptions,
      category,
      uploader,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      classicEditor,
      editorConfig,
      sliderWidth,
      albumFormOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    initData(row) {
      row.Published = false;
      row.Ordinal = 0;
      row.Name = "";
      row.Country = "";
      row.ReleaseDate = new Date();
      row.Ordinal = 0;
      row.Published = false;
      row.PictureAlbum = {
        Id: 0,
        Pictures: []
      };
      row.Career = {
        Content: ""
      };
      row.Operator = {
        Id: "00000000-0000-0000-0000-000000000000"
      };
      row.Records = [];
      row.Equipments = [];
    },
    async uploaderFilter(current, original, prevent) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    },
    onSliderEdit(image, callback) {
      callback();
    },
    async onSliderRemove(image, callback) {
      await this.$model.dispatch("picture/delete", image.Id);
      await this.reloadSlider();
      callback();
    },
    async onSliderSave(params, callback) {
      if (params.insertRows) {
        await this.$model
          .dispatch("picture/insert", params.insertRows[0])
          .then(payload => {
            this.grid.editingRow.PictureAlbum.Pictures.push(payload);
          });

        if (!this.grid.editingRow.PictureAlbum.Id) {
          await this.$model
            .dispatch("pictureAlbum/insert", this.grid.editingRow.PictureAlbum)
            .then(
              payload => {
                this.grid.editingRow.PictureAlbum.Id = payload.Id;
                this.reloadSlider();
              },
              reason => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason
                });
              }
            );
        } else {
          await this.$model
            .dispatch("pictureAlbum/update", this.grid.editingRow.PictureAlbum)
            .then(
              payload => {
                this.reloadSlider();
              },
              reason => {
                CloudFun.send("error", {
                  subject: "重載失敗",
                  content: reason
                });
              }
            );
        }
      }

      callback();
    },
    async reloadSlider() {
      await this.$model
        .dispatch("pictureAlbum/find", this.grid.editingRow.PictureAlbum.Id)
        .then(
          payload => {
            console.log("pictureAlbum/find", payload);
            this.$refs.sliderEditor.reload(payload.Pictures);
          },
          reason => {
            CloudFun.send("error", {
              subject: "重載失敗",
              content: reason
            });
          }
        );
    }
  }
});
</script>
<style>
.ck .ck .ck-content {
  min-height: 400px !important;
}
</style>
